<template>
  <!-- 文章列表 -->
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <div class="header-search">
            <el-form ref="form" inline :model="searchData" label-width="">
                <el-form-item label="文章标题">
                    <el-input style="width:250px" v-model="searchData.title" size="small"
                              placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="文章分类">
                    <el-select
                        style="width: 400px;"
                        v-model="searchData.category_ids"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请选择文章标签">
                        <el-option
                            v-for="item in articleCategoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" ">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
                    <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-button style="margin-bottom:20px;" type="primary" icon="el-icon-plus" size="small" @click="goAdd"> 添加
        </el-button>
        <tp-table
            :isNeedSerialNumber="true"
            :tableData="list"
            :columns="columns"
            :totalNum="total"
            :current-page.sync="currentPage"
            :pageSize.sync="page_size"
        />
    </div>
</template>

<script>
import {getList, removeArticle, getAllCategoryListAPI} from './api'

import {authBtnMixin} from '@/mixins/authBtnMixin'

const columns = [
    {
        label: '文章标题',
        prop: 'title',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['title']}</p>
        }
    },
    {
        label: '发布日期',
        prop: 'publish_date',
        minWidth: '160',
        customRender(h, row) {
            return <p>{row['publish_date']}</p>
        }
    },
    {
        label: '浏览量',
        prop: 'read_count',
        minWidth: '120',
        customRender(h, row) {
            return <p>{row['read_count']}</p>
        }
    },
    {
        label: '创建时间',
        minWidth: '180',
        prop: 'created_at',
        customRender(h, row) {
            return (
                <p class="one-line" title={row['created_at']}>
                    {row['created_at']}
                </p>
            )
        }
    },
    {
        label: '操作',
        fixed: 'right',
        minWidth: '200',
        customRender(h, row) {
            return (
                <div>
                    <el-link style="margin-right:10px;" type="primary" onClick={() => this.goEdit(row['id'])}>
                        编辑
                    </el-link>
                    <el-link type="danger" onClick={() => this.goRemove(row['id'])}>
                        删除
                    </el-link>
                </div>
            )
        }
    }
]

export default {
    name: 'Index',
    mixins: [authBtnMixin],
    data() {
        return {
            dialogVisible: false,
            isShow: false,
            list: [],
            page_size: 10,
            searchData: {
                title: '',
                category_ids: [],
                // roleId: ''
            },
            total: 0,
            currentPage: 1,
            columns,
            roleList: [],
            articleCategoryList: [],
        }
    },
    provide() {
        return {
            context: this
        }
    },
    watch: {
        currentPage() {
            this.getList()
        },
        page_size() {
            this.getList()
        }
    },

    mounted() {
        this.getList()
        this.getAllCategoryList()
    },

    methods: {
        async getList() {
            let params = {
                category_name: '通知通告',
                page: this.currentPage,
                page_size: this.page_size,
                title: this.searchData.title,
                category_ids: this.searchData.category_ids,
            }
            const res = await getList(params)
            this.list = res.data || []
            this.total = res.total || 0
        },

        getAllCategoryList() {
            getAllCategoryListAPI().then(res => {
                this.articleCategoryList = res
            })
        },


        goAdd() {
            this.$router.push({name: 'noticeAdd'})
        },
        goEdit(id) {
            this.$router.push({name: 'noticeAdd', params: {id: id || undefined}})
        },
        async goRemove(id) {
            this.$confirm('此操作将永久删除该管理员, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(() => {
                removeArticle({id}).then(() => {
                    this.$message.success('删除成功')
                    this.getList()
                })
            })
            .catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })
        },

        reset() {
            Object.assign(this._data.searchData, this.$options.data().searchData)
            this.getList()
        },
        updatePwd(id) {
            this.dialogVisible = true
        },
        savePwd() {
            this.dialogVisible = false
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
}
</style>
