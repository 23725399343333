import {
    http
} from '@/http/axios.js'


// 列表
export function getList(params) {
    return http({
        url: `/company/basic/article/list`,
        method: 'get',
        params
    })
}


export function removeArticle(params) {
    return http({
        url: `/company/basic/article/del`,
        method: 'post',
        params: params
    })
}
export function addArticle(data) {
    return http({
        url: `/company/basic/article/add`,
        method: 'post',
        data
    })
}

export function editArticle(data) {
    return http({
        url: `/company/basic/article/edit`,
        method: 'post',
        data
    })
}

export function detail(id) {
    return http({
        url: `/company/basic/article/detail`,
        method: 'get',
        params: {id : id}
    })
}


// 列表
export function getAllCategoryListAPI(params) {
    return http({
        url: `/company/basic/article/category/getAll`,
        method: 'get',
        params
    })
}


export function getCategoryListAPI(params) {
    return http({
        url: `/company/basic/article/category/list`,
        method: 'get',
        params
    })
}
